import Container from 'layout/container';
import raw from 'utils/raw';
import React from 'react';
import typograf from 'utils/typograf';
import { useIntl } from 'react-intl';
import { useStaticQuery, graphql } from 'gatsby';

import { ParallaxElement } from 'ui/animation';

import Persons from './persons';

import './team.sass';

const Team = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          email
        }
      }
    }
  `);

  const { email } = data.site.siteMetadata;
  const {
    messages: { team },
  } = useIntl();

  return (
    <Container>
      <div className="team" id="team">
        <ParallaxElement>
          <div className="team__header">{raw(typograf(team.title))}</div>
        </ParallaxElement>

        <div className="team__body">
          <Persons />
        </div>

        <ParallaxElement>
          <div className="team__footer">
            <p>{team.footer}</p>
            <a
              className="link link-small"
              href={`mailto:${email}`}
              title={team.link}
            >
              {team.link}
            </a>
          </div>
        </ParallaxElement>
      </div>
    </Container>
  );
};

export default Team;
