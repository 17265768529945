import raw from 'utils/raw';
import React from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, SplitWords, SplitChars } from 'react-gsap';
import { useIntl } from 'react-intl';
import { useStaticQuery, graphql } from 'gatsby';

import Container from 'layout/container';
import { ParallaxElement } from 'ui/animation';
import { SwitchLocale } from 'blocks';

import logo from 'images/icons/copyright.svg';
import Telegram from './telegram';

import './footer.sass';

const Footer = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          copyright
          email
          telegramChannel
        }
      }
    }
  `);
  const {
    locale,
    messages: { footer },
  } = useIntl();

  const { email, telegramChannel, copyright } = data.site.siteMetadata;

  return (
    <ParallaxElement>
      <Controller>
        <Scene reverse={false} triggerHook="onEnter">
          {(progress) => {
            const isReady = progress === 1;

            return (
              <footer className="footer">
                <Container>
                  <h3 className="footer__title">
                    {isReady && (
                      <Tween
                        delay={0.5}
                        duration={0.5}
                        ease="Back.easeOut"
                        stagger={0.09}
                        from={{ x: '-100', opacity: 0 }}
                      >
                        <SplitWords wrapper={<span className="split_letter" />}>
                          {raw(footer.title)}
                        </SplitWords>
                      </Tween>
                    )}
                  </h3>

                  <a
                    area-label={`Написать нам электронное письмо на ${email}`}
                    className="footer__email link link-inverse"
                    href={`mailto:${email}`}
                    title={footer.hint}
                  >
                    {isReady && (
                      <Tween
                        delay={0.8}
                        duration={0.5}
                        ease="Back.easeOut"
                        stagger={0.05}
                        from={{ x: '-60', opacity: 0 }}
                      >
                        <SplitChars wrapper={<span className="split_letter" />}>
                          {email}
                        </SplitChars>
                      </Tween>
                    )}
                  </a>
                  <br />
                  <Telegram link={telegramChannel} title={footer.link}>
                    {footer.link}
                  </Telegram>

                  <SwitchLocale locale={locale} />

                  <img
                    src={logo}
                    className="footer__copyright"
                    alt={copyright}
                  />
                </Container>
              </footer>
            );
          }}
        </Scene>
      </Controller>
    </ParallaxElement>
  );
};

export default Footer;
