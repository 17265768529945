import React, { useContext } from 'react';
import Sidebar from 'react-sidebar';
import useKey from 'use-key-hook';

import Context from 'store/panel';

import Inner from './inner';

// Styles
import styles from './styles';

// Sass
import './panel.sass';

const Panel = () => {
  const {
    panel: { isOpen, handleClose, component },
  } = useContext(Context);

  useKey(
    () => {
      handleClose();
    },
    {
      detectKeys: [27],
    },
  );

  return (
    <Sidebar
      onSetOpen={handleClose}
      open={isOpen}
      overlayClassName="overlay"
      sidebar={(
        <Inner
          component={component}
          handleClose={handleClose}
          isLocked={isOpen}
        />
      )}
      pullRight
      styles={styles(isOpen)}
    >
      <></>
    </Sidebar>
  );
};

export default Panel;
