import cn from 'classnames';
import React from 'react';
import {
  string, oneOf, func, bool,
} from 'prop-types';

import './button.sass';

const Button = ({
  active,
  children,
  className,
  disabled,
  onClick,
  type,
  variant,
}) => {
  const buttonDecoration = cn('button', [`button-${variant}`], { 'button-active': active }, { [className]: className });

  return (
    <button
      className={buttonDecoration}
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  active: bool,
  children: string,
  className: string,
  disabled: bool,
  onClick: func,
  type: oneOf(['button', 'submit', 'reset'], string),
  variant: oneOf(['primary', 'secondary', 'toggle'], string),
};

Button.defaultProps = {
  active: null,
  children: 'Button',
  className: null,
  disabled: false,
  onClick: () => false,
  type: 'button',
  variant: 'primary',
};

export default Button;
