import Media from 'react-media';
import React from 'react';
import { string, node, oneOfType } from 'prop-types';

import screen from 'utils/screen';

const Desktop = ({ children }) => (
  <Media
    query={screen.md}
    render={() => children}
  />
);

Desktop.propTypes = {
  children: oneOfType([node, string]).isRequired,
};

export default Desktop;
