import * as Scroll from 'react-scroll';
import cn from 'classnames';
import React from 'react';
import StickyNav from 'react-sticky-nav/index.js' // eslint-disable-line
import { HamburgerButton } from 'react-hamburger-button';
import { useLocation } from '@reach/router';
import { useToggle } from 'react-hooks-lib';

import Container from 'layout/container';
import { Logo } from 'ui';
import { Mobile, Desktop } from 'ui/media';
import { Propeller, Nav, Discuss } from 'blocks';

import settings from 'utils/settings';

import './header.sass';

const Header = () => {
  // Hooks
  const { on, toggle } = useToggle(false);
  const { pathname } = useLocation();
  const isRoot = pathname === '/';

  // Handles
  const handleToTop = () => {
    const scroll = Scroll.animateScroll;

    scroll.scrollTo(100);
  };

  if (!isRoot) {
    return (
      <Container className="header">
        <Logo />
      </Container>
    );
  }

  return (
    <StickyNav
      render={({ position, ref }) => {
        // const isHidden = position === 'hidden';
        // const isPinned = position === 'pinned';
        const isScrolled = position !== 'unfixed';

        // classNames
        const headerCN = cn('header', {
          'header-scrolled': isScrolled,
          'header-opened': on,
        });
        const navCN = cn('header__nav', { 'header__nav-scrolled': isScrolled });
        const controllsCN = cn('header__controlls', {
          'header__controlls-scrolled': isScrolled,
        });

        return (
          <header ref={ref} className={headerCN}>
            <Container className="header__inner">
              {/* Body */}
              {!isScrolled && (
                <div className="header__logo">
                  <Logo />
                </div>
              )}

              <div className={controllsCN}>
                <Discuss />

                <Mobile>
                  <span className="header__hamburger">
                    <HamburgerButton
                      color={settings.colors.brandPrimary}
                      onClick={toggle}
                      open={on}
                      strokeWidth={2}
                    />
                  </span>
                </Mobile>
              </div>

              {/* Nav */}
              <div className={navCN}>
                {isScrolled && (
                  <button
                    className="header__shortcut_logo"
                    onClick={handleToTop}
                    type="button"
                  >
                    <Propeller />
                  </button>
                )}

                <Desktop>
                  <Nav />
                </Desktop>
              </div>

              {/* Bottom */}
              {on && (
                <Mobile>
                  <div className="header__bottom">
                    <Nav />
                  </div>
                </Mobile>
              )}
            </Container>
          </header>
        );
      }}
    />
  );
};

export default Header;
