/* List animation */

import React from 'react';
import { node, number } from 'prop-types';
import { Tween } from 'react-gsap';

const AnimatedList = ({ children, delay }) => (
  <Tween
    from={{ x: '-80px', opacity: 0 }}
    delay={delay}
    duration={0.7}
    ease="Back.easeOut"
    stagger={0.3}
  >
    {children}
  </Tween>
);

AnimatedList.propTypes = {
  children: node.isRequired,
  delay: number,
};

AnimatedList.defaultProps = {
  delay: 0,
};

export default AnimatedList;
