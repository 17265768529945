import raw from 'utils/raw';
import React, { Fragment } from 'react';
import typograf from 'utils/typograf';
import { arrayOf, string, shape } from 'prop-types';

import InteractiveList from 'blocks/interactive_list';

const Additional = ({ items }) => {
  const list = items;

  return (
    <dl className="list_content accordion__row">
      {list.map(({ title, content }) => (
        <Fragment key={title}>
          <dt className="list_content__title">{raw(typograf(`${title}:`))}</dt>
          <dd className="list_content__content">
            {/* {raw(typograf(content))} */}
            <InteractiveList list={content} />
          </dd>
        </Fragment>
      ))}
    </dl>
  );
};

Additional.propTypes = {
  items: arrayOf(
    shape({ title: string.isRequired, content: arrayOf(string).isRequired }),
  ),
};

Additional.defaultProps = {
  items: null,
};

export default Additional;
