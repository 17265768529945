import cn from 'classnames';
import raw from 'utils/raw';
import React from 'react';
import typograf from 'utils/typograf';
import { string, shape, number } from 'prop-types';
import { useToggle } from 'react-hooks-lib';
import { Tween } from 'react-gsap';

import { FadeOut } from 'ui/animation';

import consultation from 'images/consultation.svg';
import design from 'images/design.svg';
import develop from 'images/develop.svg';
import grow from 'images/grow.svg';
import Additional from './additional';

const images = {
  consultation,
  design,
  develop,
  grow,
};

const Item = ({
  item: {
    title, content, image, additional,
  }, i,
}) => {
  // Hooks
  const { on, toggle } = useToggle(false);

  // Constants
  const control = on ? 'play' : 'stop';

  // Class names
  const controlCN = cn('accordion__control', {
    'accordion__control-active': on,
  });
  const tabCN = cn('accordion__tab', { 'accordion__tab-closed': !on });

  return (
    <>
      {!on && (
        <button
          aria-controls={`section_${i + 1}`}
          aria-expanded={on}
          className={controlCN}
          onClick={toggle}
          role="tab"
          type="button"
        >
          {title}
        </button>
      )}

      <div className={tabCN} role="presentation">
        <div className="accordion__content">
          <div className="accordion__left">
            <FadeOut playState={control}>
              <button type="button" onClick={toggle}>
                <h2 className="accordion__title">{title}</h2>
              </button>
            </FadeOut>

            <FadeOut delay={0.4} playState={control}>
              <div className="accordion__row">
                {content && raw(typograf(content))}
                {additional && (
                  <div className="accordion__additional">
                    <Additional items={additional} />
                  </div>
                )}
              </div>
            </FadeOut>
          </div>

          {on && (
            <div className="accordion__right">
              <Tween
                from={{ rotationY: 180, transformOrigin: 'center', opacity: 0 }}
                ease="Back.easeOut"
                transition={0.3}
                delay={0.2}
              >
                <img
                  src={images[image]}
                  alt={title}
                  className="accordion__image"
                />
              </Tween>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

Item.propTypes = {
  i: number.isRequired,
  item: shape({ title: string.isRequired }).isRequired,
  content: string,
};

Item.defaultProps = {
  content: null,
};

export default Item;
