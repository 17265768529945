import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import LocalizedLink from 'ui/localized_link';

import logo from 'images/logo.svg';

import './logo.sass';

const Logo = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          copyright
        }
      }
    }
  `);

  const { copyright } = data.site.siteMetadata;

  return (
    <LocalizedLink
      to="/"
      className="logo"
      activeStyle={{ cursor: 'default', pointerEvents: 'none' }}
      title={copyright}
    >
      <img src={logo} className="logo__image" alt={copyright} />
    </LocalizedLink>
  );
};


export default Logo;
