const settings = {
  colors: {
    brandPrimary: '#076CFF',
    brandSecondary: '#FF6400',
  },
  zIndex: {
    header: 3000,
  },
};

export default settings;
