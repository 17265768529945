import raw from 'utils/raw';
import React from 'react';

const icon = `<svg width="35" height="35" xmlns="http://www.w3.org/2000/svg">
  <path d="M35 0v35H0V0h35zM22.314 7.358h-4.238c-2.194 0-3.05 1.415-3.05 2.753v4.885h-2.333v4.448h2.334V35h4.69V19.444h2.566v-4.448h-2.566V12.39c0-.591.147-.856.777-.856h1.82V7.358z" fill="#057BFF" fill-rule="nonzero"/>
</svg>`;

const Facebook = () => <>{raw(icon)}</>;

export default Facebook;
