import React from 'react';
import { Link } from 'react-scroll';
import { useIntl } from 'react-intl';
import { useStaticQuery, graphql } from 'gatsby';

import { AnimatedList } from 'ui/animation';

import './nav.sass';

const Nav = () => {
  const data = useStaticQuery(graphql`
    {
      allNavYaml {
        edges {
          node {
            disabled
            id
            label {
              ru
              en
            }
            slug
            offset
          }
        }
      }
    }
  `);
  const list = data.allNavYaml.edges;
  const { locale } = useIntl();

  return (
    <nav className="nav" role="navigation">
      <ul className="nav__list">
        <AnimatedList delay={0.6}>
          {list.map(({
            node: {
              disabled, id, label, slug, offset,
            },
          }) => {
            if (disabled) return null;
            const title = label[locale];

            return (
              <li className="nav__item" key={id}>
                <Link
                  activeClass="nav__link-active"
                  className="nav__link"
                  duration={600}
                  offset={offset}
                  smooth
                  spy
                  to={slug}
                >
                  {title}
                </Link>
              </li>
            );
          })}
        </AnimatedList>
      </ul>
    </nav>
  );
};

export default Nav;
