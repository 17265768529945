import React from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import { node, number } from 'prop-types';
import { Tween } from 'react-gsap';

const isEven = (num) => !(num % 2);
const horizontalOffset = 70;
const verticalOffset = 100;

const ProjectCardParallax = ({ i, children }) => (
  <Controller>
    <Scene duration={170} triggerHook="onEnter">
      <Tween
        from={{
          opacity: 0.8,
          y: verticalOffset,
          x: isEven(i) ? -horizontalOffset : horizontalOffset,
        }}
      >
        <div>{children}</div>
      </Tween>
    </Scene>
  </Controller>
);

ProjectCardParallax.propTypes = {
  children: node.isRequired,
  i: number.isRequired,
};

export default ProjectCardParallax;
