import cn from 'classnames';
import React from 'react';
import { string, oneOf } from 'prop-types';

import Close from './close';
import Facebook from './facebook';
import Instagram from './instagram';

import './icon.sass';

const icons = {
  close: <Close />,
  facebook: <Facebook />,
  instagram: <Instagram />,
};

const Icon = ({ type }) => {
  const iconCN = cn('icon', `icon-${type}`);

  return (
    <span className={iconCN}>{icons[type]}</span>
  );
};

Icon.propTypes = {
  type: oneOf(['close', 'facebook', 'instagram'], string).isRequired,
};

export default Icon;
