import React from 'react';
import { string, arrayOf, shape } from 'prop-types';

import { ExternalLink } from 'ui';

import Icon from 'ui/icon';

import './links.sass';

const Links = ({ facebook, instagram, links }) => {
  const collection = links;

  return (
    <ul className="links">
      <ul className="links__socials">
        {instagram && (
          <li className="links__item">
            <ExternalLink
              className="links__link link"
              to={instagram}
              label="instagram"
              title={instagram}
            >
              <Icon type="instagram" />
            </ExternalLink>
          </li>
        )}

        {facebook && (
          <li className="links__item">
            <ExternalLink
              className="links__link link"
              to={facebook}
              label="facebook"
              target="_blank"
              title={facebook}
            >
              <Icon type="facebook" />
            </ExternalLink>
          </li>
        )}
      </ul>

      {collection && collection.map(({ href, label }) => (
        <li className="links__item" key={href}>
          <ExternalLink
            className="links__link link"
            to={href}
            title={href}
          >
            {label}
          </ExternalLink>
        </li>
      ))}
    </ul>
  );
};

Links.propTypes = {
  facebook: string,
  instagram: string,
  links: arrayOf(shape({ href: string.isRequired, label: string.isRequired })),
};

Links.defaultProps = {
  facebook: null,
  instagram: null,
  links: null,
};

export default Links;
