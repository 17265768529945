import Img from 'gatsby-image';
import raw from 'utils/raw';
import React from 'react';
import typograf from 'utils/typograf';
import { string, shape } from 'prop-types';

import Links from '../links';

import './person_card.sass';

const PersonCard = ({
  image,
  person: {
    about,
    facebook,
    instagram,
    links,
    name,
  },
}) => (
  <figure className="person_card">
    <Img fixed={image} />

    <figcaption className="person_card__footer">
      <h3 className="person_card__name">{raw(typograf(name))}</h3>

      <p className="person_card__about">{raw(typograf(about))}</p>

      <div className="person_card__links">
        <Links
          facebook={facebook}
          instagram={instagram}
          links={links}
        />
      </div>
    </figcaption>
  </figure>
);

PersonCard.propTypes = {
  image: shape({}).isRequired,
  person: shape({
    about: string.isRequired,
    name: string.isRequired,
  }).isRequired,
};

export default PersonCard;
