import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { ParallaxElement } from 'ui/animation';

import PersonCard from '../person_card';

import './persons.sass';

const Persons = () => {
  const data = useStaticQuery(graphql`
    {
      teamYaml {
        persons {
          id
          name
          about
          avatar
          facebook
          instagram
          links {
            href
            label
          }
        }
      }
      placeholder:
        file(name: {eq: "avatar_placeholder"}) {
          childImageSharp {
            fixed (width: 260, height: 260, quality: 90) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
      }

      boris:
        file(name: {eq: "boris"}) {
          childImageSharp {
            fixed (width: 260, height: 260, quality: 90) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
      }

      ilya:
        file(name: {eq: "ilya"}) {
          childImageSharp {
            fixed (width: 260, height: 260, quality: 90) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
      }
      vitalisimus:
        file(name: {eq: "vitalisimus"}) {
          childImageSharp {
            fixed (width: 260, height: 260, quality: 90) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
      }
      andrew:
        file(name: {eq: "andrew"}) {
          childImageSharp {
            fixed (width: 260, height: 260, quality: 90) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
      }
    }
  `);
  const { persons } = data.teamYaml;

  const avatars = {
    placeholder: data.placeholder.childImageSharp.fixed,
    boris: data.boris.childImageSharp.fixed,
    ilya: data.ilya.childImageSharp.fixed,
    vitalisimus: data.vitalisimus.childImageSharp.fixed,
    andrew: data.andrew.childImageSharp.fixed,
  };

  return (
    <ul className="persons">
      {persons.map((person) => (
        <ParallaxElement key={person.id}>
          <li className="persons__item">
            <PersonCard
              image={avatars[person.avatar] || avatars.placeholder}
              person={person}
            />
          </li>
        </ParallaxElement>
      ))}
    </ul>
  );
};

export default Persons;
