import raw from 'utils/raw';
import React from 'react';
import typograf from 'utils/typograf';
import { string, arrayOf } from 'prop-types';

import { AnimatedList } from 'ui/animation/';

const Features = ({ items }) => {
  if (!items) return false;

  return (
    <ul className="features_list">
      <AnimatedList>
        {items.map((item) => (
          <li className="features_list__item" key={item}>
            {raw(typograf(item))}
          </li>
        ))}
      </AnimatedList>
    </ul>
  );
};

Features.propTypes = {
  items: arrayOf(string),
};

Features.defaultProps = {
  items: null,
};

export default Features;
