/* Fade Out Aimation */

import React from 'react';
import {
  node, oneOfType, string, number,
} from 'prop-types';
import { Tween } from 'react-gsap';

const FadeOut = ({ children, delay, playState }) => (
  <Tween
    delay={delay}
    duration={0.3}
    ease="Back.easeOut"
    from={{ x: -100, opacity: 0 }}
    playState={playState || 'play'}
  >
    {children}
  </Tween>
);

FadeOut.propTypes = {
  children: oneOfType([node, string]).isRequired,
  delay: number,
  playState: string,
};

FadeOut.defaultProps = {
  delay: 0.2,
  playState: null,
};

export default FadeOut;
