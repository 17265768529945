module.exports = {
  ru: {
    path: 'ru',
    locale: 'По-русски',
    default: true,
  },
  // en: {
  //   path: 'en',
  //   locale: 'In English',
  // },
};
