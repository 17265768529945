import raw from 'utils/raw';
import React from 'react';
import typograf from 'utils/typograf';
import { injectIntl } from 'react-intl';
import { shape } from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Container from 'layout/container';

import './hero.sass';

const Hero = ({
  intl: {
    messages: {
      hero: { text },
    },
  },
}) => {
  const data = useStaticQuery(graphql`
    {
      allDataYaml {
        nodes {
          direction
        }
      }
    }
  `);
  const { direction } = data.allDataYaml.nodes[0];

  return (
    <Container>
      <div className="hero">
        <h1 className="hero__text">{raw(typograf(text))}</h1>

        <ul className="list list-row">
          {direction.map((item) => (
            <li className="list__item" key={item}>
              {item}
            </li>
          ))}
        </ul>
      </div>
    </Container>
  );
};

Hero.propTypes = {
  intl: shape({}).isRequired,
};

export default injectIntl(Hero);
