import React from 'react';
import { string, node, oneOfType } from 'prop-types';

const ExternalLink = ({
  children, className, label, title, to,
}) => (
  <a
    aria-label={label}
    className={className}
    href={to}
    rel="nofollow noopener noreferrer"
    target="_blank"
    title={title}
  >
    {children}
  </a>
);

ExternalLink.propTypes = {
  children: oneOfType([node, string]).isRequired,
  className: string,
  label: string,
  title: string,
  to: string.isRequired,
};

ExternalLink.defaultProps = {
  className: 'link link-external',
  label: null,
  title: 'external link',
};

export default ExternalLink;
