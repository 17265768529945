import React from 'react';
import typograf from 'utils/typograf';
import { string } from 'prop-types';

import './telegram.sass';

const Telegram = ({ title, link, children }) => (
  <a
    className="telegram"
    href={link}
    rel="nofollow noopener noreferrer"
    target="_blank"
    title={title}
  >
    {typograf(children)}
  </a>
);

Telegram.propTypes = {
  children: string.isRequired,
  link: string.isRequired,
  title: string.isRequired,
};

export default Telegram;
