import raw from 'utils/raw';
import React from 'react';

const icon = `<svg width="35" height="35" xmlns="http://www.w3.org/2000/svg">
    <path d="M35 0v35H0V0h35zM17.383 7.778c-5.37 0-9.722 4.353-9.722 9.722 0 5.37 4.353 9.722 9.722 9.722a9.722 9.722 0 009.723-9.722h-3.89a5.841 5.841 0 11-1.71-4.122 5.763 5.763 0 011.71 4.122h3.89c0-5.37-4.353-9.722-9.723-9.722zm10.111-3.111a1.944 1.944 0 100 3.889 1.944 1.944 0 000-3.89z" fill="#057BFF" fill-rule="nonzero"/>
  </svg>`;

const Instagram = () => <>{raw(icon)}</>;

export default Instagram;
