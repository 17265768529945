import cn from 'classnames';
import FluidText from 'react-fluid-text';
import React from 'react';
import { string, shape, arrayOf } from 'prop-types';
import { useHover, useToggle } from 'react-hooks-lib';
import { useStaticQuery, graphql } from 'gatsby';

import { SlideShow, Button, ExternalLink } from 'ui';

import Features from './features';
import Results from './results';

import './project_card.sass';

const ProjectCard = ({
  project: {
    name, features, status, results, slug,
  },
}) => {
  // Data
  const data = useStaticQuery(graphql`
    {
      algotec: allFile(
        sort: { fields: name }
        filter: { relativeDirectory: { eq: "projects/algotec" } }
      ) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 720, maxHeight: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      armata: allFile(
        sort: { fields: name }
        filter: { relativeDirectory: { eq: "projects/armata" } }
      ) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 720, maxHeight: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      bo: allFile(
        sort: { fields: name }
        filter: { relativeDirectory: { eq: "projects/bo" } }
      ) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 720, maxHeight: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      gf: allFile(
        sort: { fields: name }
        filter: { relativeDirectory: { eq: "projects/gf" } }
      ) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 720, maxHeight: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      orh: allFile(
        sort: { fields: name }
        filter: { relativeDirectory: { eq: "projects/orh" } }
      ) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 720, maxHeight: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      shift: allFile(
        sort: { fields: name }
        filter: { relativeDirectory: { eq: "projects/shift" } }
      ) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 720, maxHeight: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      gl: allFile(
        sort: { fields: name }
        filter: { relativeDirectory: { eq: "projects/gl" } }
      ) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 720, maxHeight: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      uvas: allFile(
        sort: { fields: name }
        filter: { relativeDirectory: { eq: "projects/uvas" } }
      ) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 720, maxHeight: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      freshcacao: allFile(
        sort: { fields: name }
        filter: { relativeDirectory: { eq: "projects/freshcacao" } }
      ) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 720, maxHeight: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      fnow: allFile(
        sort: { fields: name }
        filter: { relativeDirectory: { eq: "projects/fnow" } }
      ) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 720, maxHeight: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      mosreg: allFile(
        sort: { fields: name }
        filter: { relativeDirectory: { eq: "projects/mosreg" } }
      ) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 720, maxHeight: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      zencar: allFile(
        sort: { fields: name }
        filter: { relativeDirectory: { eq: "projects/zencar" } }
      ) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 720, maxHeight: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `);

  const imagesCollection = slug ? data[slug].nodes : null;

  // Hooks
  const { hovered, bind } = useHover();
  const { on, toggle } = useToggle(false);

  // Class names
  const innerCN = cn('project_card__inner', { 'project_card__inner-open': on });

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...bind} className="project_card">
      <SlideShow
        onHover={hovered && !on}
        images={imagesCollection}
        name={name}
      />

      <div className={innerCN}>
        <ExternalLink
          className="project_card__name link link-inverse link-undecorated"
          label={name}
          title={name}
          style={{ display: 'inline' }}
          to={`http://${name}`}
        >
          <FluidText as="h4" maxFontSize={40} text={name} compressor={1.4} />
        </ExternalLink>

        <span className="project_card__status">{!on && status}</span>

        <div className="project_card__body">
          {on ? <Results>{results}</Results> : <Features items={features} />}
        </div>
      </div>

      {results && (
        <Button
          active={on}
          className="project_card__button"
          onClick={toggle}
          variant="toggle"
        >
          {on ? 'Что делали' : 'Результат'}
        </Button>
      )}
    </div>
  );
};

ProjectCard.propTypes = {
  project: shape({
    description: string,
    features: arrayOf(string),
    name: string.isRequired,
    results: string,
    slug: string,
    status: string.isRequired,
  }).isRequired,
};

export default ProjectCard;
