import React from 'react';
import { string, arrayOf, shape } from 'prop-types';

import Item from './item';

import './accordion.sass';

const Accordion = ({ list }) => (
  <ul className="accordion" role="tablist">
    {list.map((item, i) => (
      <li className="accordion__item" key={item.id}>
        <Item item={item} i={i} />
      </li>
    ))}
  </ul>
);

Accordion.propTypes = {
  list: arrayOf(
    shape({ id: string.isRequired }).isRequired,
  ).isRequired,
};

export default Accordion;
