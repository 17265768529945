import 'sass/screen.sass';

import BodyClassName from 'react-body-classname';
import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import { node, string, oneOf } from 'prop-types';

import Context from 'store/panel';

// Messages
import en from 'data/locales/en.json';
import ru from 'data/locales/ru.json';

// Custom components
import Panel from 'ui/panel';

import Header from './header';
import Footer from './footer';

// Constants
const defaultLanguage = 'ru';
const messages = { en, ru };

const Layout = ({ children, bodyClass, lang }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [component, setComponent] = useState(null);

  // Handlers
  const handleOpenDiscuss = () => {
    setComponent('discuss');
    setIsOpen(true);
  };

  const handleClose = () => {
    setComponent(null);
    setIsOpen(false);
  };

  return (
    <IntlProvider
      locale={lang}
      messages={messages[lang]}
      defaultLocale={defaultLanguage}
      key={lang}
    >
      <Context.Provider
        value={{
          panel: {
            isOpen,
            handleOpenDiscuss,
            handleClose,
            component,
          },
        }}
      >
        {/* Layout */}
        <Header />

        <main className="main">{children}</main>

        <Footer />

        {/* Services */}
        <BodyClassName className={bodyClass} />

        <Panel />
      </Context.Provider>
    </IntlProvider>
  );
};

Layout.propTypes = {
  children: node.isRequired,
  bodyClass: string,
  lang: oneOf(['ru', 'en'], string).isRequired,
};

Layout.defaultProps = {
  bodyClass: 'body',
};

export default Layout;
