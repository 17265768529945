import cn from 'classnames';
import React from 'react';
import { node, bool, string } from 'prop-types';

import './container.sass';

const Container = ({ children, unpadding, className }) => {
  const containerCN = cn(['container', { 'container-unpadding': unpadding }, className]);
  return (
    <div className={containerCN}>{children}</div>
  );
};

Container.propTypes = {
  children: node.isRequired,
  className: string,
  unpadding: bool,
};

Container.defaultProps = {
  className: null,
  unpadding: false,
};

export default Container;
