import React from 'react';
import { string, arrayOf } from 'prop-types';

import './interactive_list.sass';

const InteractiveList = ({ list }) => (
  <ul className="interactive_list">
    {list.map((item) => (
      <li className="interactive_list__item" key={item}>
        <span className="interactive_list__hover">{item}</span>
      </li>
    ))}
  </ul>
);

InteractiveList.propTypes = {
  list: arrayOf(string),
};

InteractiveList.defaultProps = {
  list: [],
};

export default InteractiveList;
