import * as Yup from 'yup';
import raw from 'utils/raw';
import React, { useEffect, useRef } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import typograf from 'utils/typograf';
import { Preloader, ThreeDots } from 'react-preloader-icon';
import { useIntl } from 'react-intl';
import { useMergeState } from 'react-hooks-lib';
import { useStaticQuery, graphql } from 'gatsby';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';

import Button from 'ui/button';

// Init
const formId = 'TUGm1rxjWOR';
const url = `https://formcarry.com/s/${formId}`;

// Init Values
const initFormValues = { message: undefined, contacts: undefined };

// Component
const Discuss = () => {
  // Hooks
  const fieldRef = useRef(null);

  const {
    messages: {
      form: {
        title, subtitle, validation, fields, response,
      },
    },
  } = useIntl();
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          email
          telegramManager
        }
      }
    }
  `);
  const { email, telegramManager } = data.site.siteMetadata;

  // States
  const { set, state } = useMergeState({ status: null, message: null });

  // Handles
  const handleServerResponse = (status, message) => {
    set(() => ({ status, message }));
  };

  const handleOnSubmit = async (values, actions) => {
    const formData = JSON.stringify(values);

    const sendMessage = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: formData,
    });

    const { status, message } = await sendMessage.json();

    if (status === 'error') {
      actions.setSubmitting(false);
      return handleServerResponse('ERROR', message);
    }

    actions.setSubmitting(false);
    actions.resetForm();
    return handleServerResponse('SUCCESS');
  };

  const handleSetFocus = () => fieldRef.current.focus();

  useEffect(() => {
    setTimeout(handleSetFocus, 300);
  }, []);

  // View
  if (state.status === 'SUCCESS') {
    return (
      <>
        <h2>{raw(typograf(response.title.success))}</h2>
        <br />
        <p>{raw(typograf(response.subtitle.success))}</p>
      </>
    );
  }

  if (state.status === 'ERROR') {
    return (
      <>
        <h2>
          {raw(typograf(response.title.error))}
          {state.message && (
          <span>
            {' '}
            {raw(typograf(`"${state.message}"`))}
          </span>
          )}
        </h2>
        <br />
        <p>
          <span>{raw(typograf(response.subtitle.error))}</span>
          {' '}
          <a href={`mailto:${email}`} className="link">
            {email}
          </a>
        </p>
      </>
    );
  }

  // Validation
  const formSchema = Yup.object().shape({
    contacts: Yup.string().required(validation.contacts),
  });

  return (
    /* Form */
    <Formik
      initialValues={initFormValues}
      onSubmit={handleOnSubmit}
      validationSchema={formSchema}
    >
      {({ isSubmitting, errors }) => {
        const isError = Object.keys(errors).length > 0;

        return (
          <Form noValidate className="form" role="form">
            <fieldset className="form__row">
              <legend className="form__legend">
                <h1>{raw(typograf(title))}</h1>
                <h2 className="subtitle">{raw(typograf(subtitle))}</h2>
              </legend>
            </fieldset>

            {/* Message */}
            <fieldset className="form__row">
              <Field name="message">
                {({ form: { setFieldValue } }) => (
                  <TextareaAutosize
                    ref={fieldRef}
                    className="form__input"
                    maxRows={12}
                    minRows={4}
                    onChange={({ target: { value } }) => setFieldValue('message', value)}
                  />
                )}
              </Field>

              <ErrorMessage
                className="alert alert-error"
                component="p"
                name="message"
              />
            </fieldset>

            {/* Contacts */}
            <fieldset className="form__row">
              <label
                className="form__label form__label-required"
                htmlFor="contacts"
              >
                {fields.contacts.label}
              </label>
              <Field
                className="form__input"
                id="contacts"
                type="text"
                name="contacts"
              />
              <ErrorMessage
                className="alert alert-error"
                component="p"
                name="contacts"
              />
            </fieldset>

            {/* Submit */}
            <fieldset className="form__row form__row-footer">
              <Button
                className="button"
                type="submit"
                disabled={isSubmitting || isError}
              >
                {isSubmitting ? (
                  <Preloader
                    use={ThreeDots}
                    size={24}
                    strokeWidth={6}
                    strokeColor="#FFF"
                    duration={1000}
                  />
                ) : (
                  fields.submitButton
                )}
              </Button>

              <p className="form__hint">
                <span>Также можете написать нам на почту</span>
                {' '}
                <a href={`mailto:${email}`} className="link">
                  {email}
                </a>
                , или в
                {' '}
                <a
                  className="link"
                  href={telegramManager}
                  rel="nofollow noopener noreferrer"
                  target="_blank"
                >
                  телеграм
                </a>
              </p>
            </fieldset>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Discuss;
