import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Container from 'layout/container';
import { Accordion } from 'ui';

const Skills = () => {
  const data = useStaticQuery(graphql`
    {
      allSkillsYaml {
        nodes {
          additional {
            title,
            content
          }
          content
          id
          image
          title
        }
      }
    }
  `);
  const { nodes } = data.allSkillsYaml;

  return (
    <Container unpadding>
      <div className="skills">
        <Accordion list={nodes} />
      </div>
    </Container>
  );
};

export default Skills;
