import isDefaultLocale from 'utils/is_default_locale';
import React from 'react';
import { injectIntl } from 'react-intl';
import { Link } from 'gatsby';
import {
  string, oneOfType, node, shape,
} from 'prop-types';

const LocalizedLink = ({
  to, intl: { locale }, activeStyle, activeClassName, className, title, role, children,
}) => {
  const path = isDefaultLocale(locale) ? to : `/${locale}${to}`;

  return (
    <Link
      activeStyle={activeStyle}
      activeClassName={activeClassName}
      className={className}
      role={role}
      title={title}
      to={path}
    >
      {children}
    </Link>
  );
};

LocalizedLink.propTypes = {
  activeClassName: string,
  activeStyle: shape({}),
  children: oneOfType([node, string]).isRequired,
  className: string.isRequired,
  intl: shape({ locale: string.isRequired }).isRequired,
  role: string,
  title: string.isRequired,
  to: string.isRequired,
};

LocalizedLink.defaultProps = {
  activeClassName: null,
  activeStyle: {},
  role: 'link',
};

export default injectIntl(LocalizedLink);
