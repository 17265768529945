import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Container from 'layout/container';
import { ProjectCardParallax } from 'ui/animation';

import ProjectCard from './project_card';

import './projects.sass';

const Projects = () => {
  const data = useStaticQuery(graphql`
    {
      allProjectsYaml {
        nodes {
          features
          name
          results
          slug
          status
        }
      }
    }
  `);
  const { nodes } = data.allProjectsYaml;

  if (!nodes) return false;

  return (
    <Container unpadding>
      <ul className="projects">
        {nodes.map((project, i) => (
          <li className="projects__item" key={project.name}>
            <ProjectCardParallax i={i}>
              <ProjectCard project={project} />
            </ProjectCardParallax>
          </li>
        ))}
      </ul>
    </Container>
  );
};

export default Projects;
