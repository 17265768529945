import raw from 'utils/raw';
import React from 'react';
import typograf from 'utils/typograf';
import { string, node, oneOfType } from 'prop-types';

import { ParallaxElement } from 'ui/animation';

import Container from 'layout/container';

import './segment.sass';

const Segment = ({ children, id }) => {
  const inner = typeof children === 'string' ? raw(typograf(children)) : children;

  return (
    <Container>
      <ParallaxElement>
        <div id={id} className="segment">{inner}</div>
      </ParallaxElement>
    </Container>
  );
};

Segment.propTypes = {
  children: oneOfType([node, string]).isRequired,
  id: string,
};

Segment.defaultProps = {
  id: null,
};

export default Segment;
