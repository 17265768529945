import React, { useContext } from 'react';
import { useIntl } from 'react-intl';

import Context from 'store/panel';

import Button from 'ui/button';

const Discuss = () => {
  // Context
  const {
    panel: { handleOpenDiscuss },
  } = useContext(Context);

  // intl
  const {
    messages: { buttonDiscuss },
  } = useIntl();

  return (
    <span className="discuss">
      <Button onClick={handleOpenDiscuss}>{buttonDiscuss}</Button>
    </span>
  );
};

export default Discuss;
