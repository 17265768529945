import raw from 'utils/raw';
import React from 'react';
import typograf from 'utils/typograf';
import { string } from 'prop-types';
import { Tween } from 'react-gsap';

const Results = ({ children }) => {
  if (!children) return false;

  return (
    <Tween
      duration={0.3}
      ease="Back.easeOut"
      from={{ y: 100, opacity: 0 }}
    >
      <p className="results">{raw(typograf(children))}</p>
    </Tween>
  );
};

Results.propTypes = {
  children: string,
};

Results.defaultProps = {
  children: null,
};

export default Results;
