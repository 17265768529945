import 'react-responsive-carousel/lib/styles/carousel.min.css';

import BackgroundImage from 'gatsby-background-image';
import React, { createRef } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { useDidUpdate, useDidMount } from 'react-hooks-lib';
import {
  arrayOf, shape, bool,
} from 'prop-types';

import './slide_show.sass';

const SlideShow = ({ images, onHover }) => {
  const imagesCollection = images;

  if (!images) return false;

  // Refs
  const refCarousel = createRef();

  // Handles
  const playHandle = () => {
    const carousel = refCarousel.current;

    carousel.clearAutoPlay();
    if (onHover) {
      carousel.autoPlay();
    } else {
      carousel.clearAutoPlay();
    }
  };

  // Life
  useDidUpdate(() => { playHandle(); });
  useDidMount(() => {
    playHandle();
    refCarousel.current.clearAutoPlay();
  });

  return (
    <Carousel
      autoPlay
      className="slide_show"
      infiniteLoop
      interval={2000}
      transitionTime={500}
      ref={refCarousel}
      showArrows={false}
      showIndicators={false}
      showStatus={false}
      showThumbs={false}
      stopOnHover={false}
    >
      {imagesCollection.map(({ id, childImageSharp: { fluid } }) => (
        <BackgroundImage
          className="slide_show__item"
          fluid={fluid}
          key={id}
          tag="div"
        />
      ))}
    </Carousel>
  );
};

SlideShow.propTypes = {
  images: arrayOf(shape({ })),
  onHover: bool,
};

SlideShow.defaultProps = {
  images: null,
  onHover: false,
};

export default SlideShow;
