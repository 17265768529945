import React from 'react';
import raw from 'utils/raw';

const icon = `
  <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.768 16L31.634 2.136A1.25 1.25 0 1029.866.367L16 14.233 2.134.367A1.25 1.25 0 10.366 2.135L14.232 16 .366 29.867a1.25 1.25 0 101.768 1.768L16 17.769l13.866 13.866a1.246 1.246 0 001.768 0 1.25 1.25 0 000-1.768L17.768 16z" fill="#000" fill-rule="nonzero"/>
  </svg>
`;

const Close = () => <>{raw(icon)}</>;

export default Close;
