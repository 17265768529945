import React from 'react';
import { Link } from 'gatsby';
import { string } from 'prop-types';

// Init
import locales from 'data/locales/locales';

const SwitchLocale = ({ locale }) => {
  if (locales.length === 1) return false;

  return (
    <nav role="navigation" className="switch_locale">
      <ul>
        {Object.keys(locales).map((key) => {
          const isActive = key === locale;
          const label = locales[key].locale;
          const path = locales[key].default ? '/' : `/${locales[key].path}/`;

          if (isActive) return false;

          return (
            <li className="switch_locale__item" key={locales[key].locale}>
              <Link
                className="switch_locale__link"
                to={path}
              >
                {label}
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

SwitchLocale.propTypes = {
  locale: string.isRequired,
};

export default SwitchLocale;
