import React from 'react';
import ScrollLock, { TouchScrollable } from 'react-scrolllock';
import { string, func, bool } from 'prop-types';

import Icon from 'ui/icon';
import Discuss from 'components/form/discuss';

const components = {
  default: <p>Необходимо указать компонент!</p>,
  discuss: <Discuss />,
};

const Inner = ({ component, handleClose, isLocked }) => (
  <div className="panel" role="complementary">
    <ScrollLock isActive={isLocked} />
    <button
      aria-hidden="true"
      className="panel__close"
      onClick={handleClose}
      type="button"
    >
      <Icon type="close" />
    </button>

    <TouchScrollable>
      <div className="panel__inner">
        <div className="panel__content">{components[component]}</div>
      </div>
    </TouchScrollable>
  </div>
);

Inner.propTypes = {
  component: string,
  handleClose: func.isRequired,
  isLocked: bool.isRequired,
};

Inner.defaultProps = {
  component: 'default',
};

export default Inner;
