import React from 'react';

import './propeller.sass';

const Propeller = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.33 93.86" className="propeller">
    <path d="M44.38 19.62c27.09 0 31.18 11.53 31.18 19.93v1.61c0 8.24-4.14 20.14-31.18 20.14h-2.69c-27 0-31.18-11.9-31.18-20.14v-1.61c0-8.4 4.1-19.93 31.18-19.93z" />
    <path d="M1.26 4.42a13.11 13.11 0 0115.11-3.27c11.2 5 18.85 14.41 25.76 24.71a16.13 16.13 0 012.09 4.62A10.14 10.14 0 0152 38.07c10.61-1.28 21.11-8.45 30.67-16 4.68-3.6 9.49-3.09 11 1a13.07 13.07 0 01-4.74 14.71C79 45 67 46.89 54.65 47.72a17 17 0 01-5-.48 10.16 10.16 0 01-10.47 2.9C35 60 35.94 72.62 37.7 84.72c.81 5.86-2.06 9.76-6.37 9.06A13.15 13.15 0 0121 82.31c-1.3-12.21 3-23.55 8.48-34.67a16.39 16.39 0 012.91-4.12 12.37 12.37 0 01-.39-2.13A10.2 10.2 0 0135.12 33c-6.43-8.56-17.88-14-29.23-18.52C.42 12.23-1.52 7.82 1.26 4.42z" fill="#057bff" fillRule="evenodd" />
  </svg>
);

export default Propeller;
